import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./styles.css";

const CountdownClock = () => {
  const targetDate = new Date("March 31, 2025 23:59:59").getTime();
  const [timeLeft, setTimeLeft] = useState(targetDate - new Date().getTime());
  const [prevTime, setPrevTime] = useState(timeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setPrevTime(timeLeft);
      setTimeLeft(targetDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, targetDate]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const prevTotalSeconds = Math.floor(prevTime / 1000);
    
    const days = Math.floor(totalSeconds / 86400);
    const prevDays = Math.floor(prevTotalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const prevHours = Math.floor((prevTotalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const prevMinutes = Math.floor((prevTotalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const prevSeconds = prevTotalSeconds % 60;

    const FlipUnit = ({ value, prevValue }) => (
      <motion.div 
        className="flip-box"
        initial={{ rotateX: 0 }}
        animate={{ rotateX: prevValue !== value ? [0, 40, 0] : 0 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
      >
        {value}
      </motion.div>
    );
    
    return (
      <div className="flip-clock-container">
        <div className="flip-clock">
          <FlipUnit value={Math.floor(days / 10)} prevValue={Math.floor(prevDays / 10)} />
          <FlipUnit value={days % 10} prevValue={prevDays % 10} />
        </div>
        <div className="flip-clock">
          <FlipUnit value={Math.floor(hours / 10)} prevValue={Math.floor(prevHours / 10)} />
          <FlipUnit value={hours % 10} prevValue={prevHours % 10} />
        </div>
        <div className="flip-clock">
          <FlipUnit value={Math.floor(minutes / 10)} prevValue={Math.floor(prevMinutes / 10)} />
          <FlipUnit value={minutes % 10} prevValue={prevMinutes % 10} />
        </div>
        <div className="flip-clock">
          <FlipUnit value={Math.floor(seconds / 10)} prevValue={Math.floor(prevSeconds / 10)} />
          <FlipUnit value={seconds % 10} prevValue={prevSeconds % 10} />
        </div>
      </div>
    );
  };

  return (
    <div className="wave-container">
      <div className="wave-box">
        <div className="wave-wrapper">
          <svg className="wave-svg wave1" viewBox="0 0 1440 400" preserveAspectRatio="none">
            <path fill="rgba(255, 255, 255, 0.3)" d="M0,128L160,180C320,260,640,100,960,200C1280,280,1600,120,1920,220C2240,300,2560,140,2880,240L2880,400L0,400Z"></path>
          </svg>
          <svg className="wave-svg wave2" viewBox="0 0 1440 400" preserveAspectRatio="none">
            <path fill="rgba(255, 255, 255, 0.4)" d="M0,180L120,140C240,220,480,80,720,180C960,260,1200,100,1440,200C1680,280,1920,120,2160,220L2160,400L0,400Z"></path>
          </svg>
        </div>
      </div>
      <div className="content">
        <h1 className="title">Joey's Return</h1>
        <div className="clock-box">
          {formatTime(timeLeft)}
        </div>
      </div>
    </div>
  );
};

export default CountdownClock;
